<template>
  <div class="one-column-width">
    <!-- show action result -->
    <v-snackbar v-model="snackbar" absolute top right color="success">
      <span>{{ snackBarText }}</span>
      <v-icon dark>mdi-checkbox-marked-circle</v-icon>
    </v-snackbar>

    <v-snackbar v-model="errorMessage" absolute top right color="danger">
      <span>Something went RONG, Please Try Again!</span>
      <v-icon dark>mdi-checkbox-marked-circle</v-icon>
    </v-snackbar>

    <!-- Confirm Delete Item -->

    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="380px">
        <v-card>
          <v-card-title>
            <span class="headline">Do you really want to DELETE this item?</span>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="custom-submit-button" text @click="dialog = false">No</v-btn>
            <v-btn class="custom-cancel-button" text @click="deleteItem"
              ><b-spinner small type="grow" v-if="deleting"></b-spinner>Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- form start -->
    <v-card class="add-form-title" v-if="showFormData">
      <v-card-title> Assign Form Template To Institutes </v-card-title>
      <div>
        <v-form ref="form" @submit.prevent="submit" class="custom-form">
          <v-container fluid>
            <v-col cols="12" sm="12">
              <v-row>
                <v-autocomplete
                  v-model="institutes"
                  :rules="rules.institutes"
                  :items="institutesData"
                  label="Select Institute(s)"
                  class="col-md-4"
                  multiple
                ></v-autocomplete>
                <v-autocomplete
                  v-model="forms"
                  :rules="rules.forms"
                  :items="formsData"
                  label="Select Form Template"
                  class="col-md-4"
                ></v-autocomplete>
              </v-row>
            </v-col>
          </v-container>
          <v-card-actions>
            <v-btn :disabled="submitting" class="custom-submit-button" type="submit"
              ><b-spinner small type="grow" v-if="submitting"></b-spinner>
              <span v-if="!editId">Submit</span>
              <span v-if="editId">Update</span>
            </v-btn>
            <v-btn text @click="cancelForm" class="custom-cancel-button">Cancel</v-btn>
          </v-card-actions>
        </v-form>
      </div>
    </v-card>
    <!-- form end -->

    <!-- table start -->
    <v-card>
      <v-card-title>
        Institute Admission Forms
        <div class="my-2 ml-2">
          <v-btn
            class="btn btn-sm btn-success font-weight-bold py-2 px-3 px-xxl-5 my-1"
            fab
            small
            @click="showForm"
          >
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </div>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search Institute templates"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="tableData" :search="search">
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            class="btn btn-sm btn-danger font-weight-bold py-2 px-3 px-xxl-5 my-1"
            fab
            small
            @click="askUserAboutDelete(item)"
          >
            <v-icon dark>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <!-- table end -->
  </div>
</template>

<script>
import AdmissionForm from "@/MainServices/AdmissionForm.js";
import { required, minLength } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      institutes: [],
      forms: null,

      institutesData: [],
      formsData: [],
      editId: null,
      deleteId: null,
      dialog: false,
      rules: {
        institutes: [(val) => !!val || "Please select institute"],
        forms: [(val) => !!val || "Form Template is required"],
      },
      snackbar: false,
      snackBarText: null,
      submitting: false,
      deleting: false,
      errorMessage: false,
      search: "",
      showFormData: false,
      headers: [
        {
          text: "Institute Name",
          align: "left",
          sortable: false,
          value: "user.name",
          width: "45%",
        },
        {
          text: "Form Template",
          align: "left",
          sortable: false,
          value: "name",
          width: "45%",
        },

        { text: "Actions", value: "actions", sortable: false },
      ],
      tableData: [],
      tabsData: [],
    };
  },
  validations: {
    form: {
      required,
      $each: {
        institutes: { minLength: minLength(1), required },
        forms: { minLength: minLength(1), required },
      },
    },
  },
  components: {},

  computed: {},

  methods: {
    getInititalData() {
      AdmissionForm.getInstitesForms()
        .then((res) => {
          this.tableData = res.data;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // main methods
    showForm() {
      this.editId = null;
      this.showFormData = true;
      this.resetForm();
    },
    showEditForm(item) {
      this.form = [
        {
          tab: item.tab,
          name: item.name,
          default: item.default,
        },
      ];
      this.editId = item.id;
      this.showFormData = true;
    },
    cancelForm() {
      this.resetForm();

      this.showFormData = false;
    },
    resetForm() {
      (this.form = [
        {
          institutes: [],
          forms: null,
        },
      ]),
        (this.editId = null);
      setTimeout(() => {
        this.$refs.form.reset();
      }, 100);
    },

    submit() {
      this.submitting = true;
      if (this.editId) {
        AdmissionForm.editForm(this.form, this.editId)
          .then((res) => {
            this.resetForm();
            this.getInititalData();
            this.submitting = false;
            this.snackBarText = "Templates Updated successful!";
            this.snackbar = true;
          })
          .catch((error) => {
            this.errorMessage = true;
          });
      } else {
        let data = {
          institutes: this.institutes,
          forms: this.forms,
        };
        AdmissionForm.addNewTemplateToInstitute(data)
          .then((res) => {
            this.resetForm();
            this.getInititalData();
            this.submitting = false;
            this.snackBarText = "Templates Added successful!";
            this.snackbar = true;
          })
          .catch((error) => {
            this.errorMessage = true;
          });
      }
    },
    askUserAboutDelete(item) {
      this.deleteId = item.id;
      this.dialog = true;
    },
    deleteItem() {
      this.deleting = true;
      AdmissionForm.deleteInstituteTemplate(this.deleteId)
        .then((res) => {
          this.editId = null;
          this.deleting = false;
          this.dialog = false;
          this.resetForm();
          this.getInititalData();
          this.snackBarText = "Template Deleted successful!";
          this.snackbar = true;
        })
        .catch((error) => {
          this.errorMessage = true;
          this.dialog = false;
        });
    },
  },
  mounted() {
    this.getInititalData();
    AdmissionForm.getForm()
      .then((res) => {
        res.data.map((el) => {
          this.formsData.push({ value: el.name, text: el.name });
        });
      })
      .catch((e) => {});
    AdmissionForm.getInstituesList()
      .then((res) => {
        res.data.map((el) => {
          this.institutesData.push({ value: el.id, text: el.name });
        });
      })
      .catch((e) => {});
  },
};
</script>
